<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back">

		<app-content-head-actions-item icon="preview" text="View" :url="item.url" />

		<template v-if="$authorised('con/registration/access', 'write')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

		</template>

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-text label="Name" :maxlength="32" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Default Home" v-model="model.default" :validation="$v.model.default" :options="$constants.registration.page.defaultName" :descriptions="$constants.registration.page.defaultDescription" />
			<app-input-checklist :locked="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Type" v-model="model.type" :validation="$v.model.type" :options="$constants.registration.page.typeName" :descriptions="$constants.registration.page.typeDescription" />
			<app-input-text v-if="!model.default && !isNew" label="URL" :textPrefix="prefixURL" v-model="model.slug" :validation="$v.model.slug" />
			<app-input-date :time="true" v-if="isSales" label="Opens from" placeholder="Enter date..." :toggle="model.from.enabled" v-on:toggle="model.from.enabled = !model.from.enabled" v-model="model.from.date" :validation="$v.model.from.date" />
			<app-input-date :time="true" v-if="isSales" label="Closes on" placeholder="Enter date..." :toggle="model.to.enabled" v-on:toggle="model.to.enabled = !model.to.enabled" v-model="model.to.date" :validation="$v.model.to.date" />
			<app-input-items v-if="isRegistration" placeholder="Add item" :list="true" api="convention/registration/item" label="Default Charges" v-model="model.addons" :validation="$v.model.addons" notes="Add default charges to be applied to every badge purchased." />

		</app-content-box>

		<app-content-section :icon="(item.count.blocks) ? 'blocks' : 'warning'" :danger="!item.count.blocks" v-if="!isNew" route="Convention.Registration.Pages.Edit.Blocks" :params="{ page: model.id }" title="Content blocks" :description="(item.count.blocks) ? 'Manage the blocks that make up the content of this page.' : 'You have no added any content blocks yet.'" :count="item.count.blocks" :forceCount="true" />

		<app-content-box :tabs="contentTabs" :readonly="$authorised('con/registration/access', 'read')" v-if="!isNew">

			<template v-slot:design>

				<app-input-image label="Background" placeholder="Select image..." v-model="model.design.head.image" :validation="$v.model.design.head.image" />

			</template>

			<template v-slot:countdown>

				<app-input-content placeholder="Enter message..." v-model="model.message.open" :validation="$v.model.message.open" />

			</template>

			<template v-slot:closed>
			
				<app-input-content placeholder="Enter message..." v-model="model.message.close" :validation="$v.model.message.close" />

			</template>

			<template v-slot:completed>

				<app-input-content placeholder="Enter message..." v-model="model.message.success" :validation="$v.model.message.success" />

			</template>
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/registration/access', 'read'),
			references: {
				types: []
			},
			themeOptions: [
				{ value: 'default', text: 'Cardboard Events Blue' }
			],
			model: {
				id: false,
				name: '',
				slug: '',
				default: 0,
				type: 0,
				from: {
					enabled: false,
					date: 0
				},
				to: {
					enabled: false,
					date: 0
				},
				message: {
					open: '',
					close: '',
					success: ''
				},
				design: {
					theme: 'default',
					head: {
						image: ''
					}
				},
				addons: []
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			from: {},
			to: {},
			message: {},
			design: {
				head: {}
			}
		}
	},

	computed: {

		contentTabs: function() {

			return [
				{ name: 'design', text: 'Design' },
				{ name: 'countdown', text: 'Countdown', enabled: !this.isNew && this.model.from.enabled },
				{ name: 'closed', text: 'Closed', enabled: !this.isNew && this.model.to.enabled },
				{ name: 'completed', text: 'Completed', enabled: !this.isNew && this.model.type !== this.$constants.registration.page.type.info },
			]

		},

		prefixURL: function() {

			return this.$organisation.slug + '/' + this.$convention.slug + '/'

		},

		isSales: function() {

			return this.$_.contains([
				this.$constants.registration.page.type.registration,
				this.$constants.registration.page.type.thirdparty
			], this.model.type)

		},

		isRegistration: function() {

			return this.$_.contains([
				this.$constants.registration.page.type.registration
			], this.model.type)

		}

	}

}

</script>

<style scoped>

</style>